/* eslint-disable no-var, no-console */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { useIndicatorStore } from '~/store/useIndicatorStore';
import { useSoundStore } from '~/modules/SDK/sound/useSoundStore';
const sounds = useSoundStore.getState();
let exit_sound_flag_ = 0;
let entry_sound_flag_ = 1;
export const gaptrade = createIndicator({
    displayName: '閃電多空',
    id: 'dayopentrade',
    constructorScope: {
        init(context, inputCallback) {
            localStorage.setItem('signalInitializedTimestamp', new Date().getTime().toString());
        },
        main(context, inputCallback) {
            const state = useIndicatorStore.getState();
            const open = this.PineJS.Std.open(this._context);
            const close = this.PineJS.Std.close(this._context);
            const high = this.PineJS.Std.high(this._context);
            const low = this.PineJS.Std.low(this._context);
            const interval = this.PineJS.Std.interval(this._context);
            const open_array = this._context.new_var(open);
            const close_array = this._context.new_var(close);
            const bState = this._context.new_var();
            const bsentry = this._context.new_var();
            const bDt = this._context.new_var();
            const entryPrice = this._context.new_var();
            const pvflag = this._context.new_var();
            const itime = this.PineJS.Std.time(this._context);
            const currentdate = new Date().getDate();
            const idate = new Date(itime).getDate();
            const idate_array = this._context.new_var(itime);
            const start_h = new Date(itime).getHours();
            const start_m = new Date(itime).getMinutes();
            const last_start_h = new Date(idate_array.get(1)).getHours();
            const last_start_m = new Date(idate_array.get(1)).getMinutes();
            //----------------------------------------
            close_array.get(1);
            open_array.get(1);
            idate_array.get(1);
            bState.get(1);
            bState.get(2);
            bsentry.get(1);
            //----------------------------------------
            let pv0 = NaN;
            let pv1 = NaN;
            let pb1 = NaN;
            let pb2 = NaN;
            let pb3 = NaN;
            let pb4 = NaN;
            let ps1 = NaN;
            let ps2 = NaN;
            let ps3 = NaN;
            let ps4 = NaN;
            const p1 = 0.002;
            const p2 = 0.005;
            const p3 = 0.01;
            const p4 = 0.02;
            //----------------------------------------
            /**
             * 此策略邏輯是用當日開與前一夜盤收作缺口比較，並只在當日出訊 因有指定日期判斷邏輯，所以會讓前N天無任何訊號 因此部位bstate.get(2) === NaN
             * 在夜盤收前加入以下邏輯可解決
             */
            if ( /** Currentdate === idate && */start_h === 4 && start_m === 59) {
                bState.set(0);
            }
            /** 做多條件 */
            const condition_long = 
            /** Currentdate === idate && */ start_h === 8 && start_m === 45 && open > close_array.get(1);
            /** 做空條件 */
            const condition_short = 
            /** Currentdate === idate && */ start_h === 8 &&
                start_m === 45 &&
                open <= close_array.get(1);
            /** 部位確立 */
            if (condition_long) {
                bState.set(1);
                bDt.set(itime);
            }
            else if (condition_short) {
                bState.set(-1);
                bDt.set(itime);
            }
            /** 訊號出訊至圖表 */
            if (bState.get(0) === 1 && bState.get(1) !== 1) {
                pv0 = 1;
                pv1 = NaN;
                pvflag.set(0);
                entryPrice.set(open);
                state.entryPrice = Number(open);
                state.marketPosition = Number(1);
            }
            else if (bState.get(0) === -1 && bState.get(1) !== -1) {
                pv0 = NaN;
                pv1 = 1;
                pvflag.set(0);
                entryPrice.set(open);
                state.entryPrice = Number(open);
                state.marketPosition = Number(-1);
            }
            /** 停利停醒 */
            if (bState.get(0) === 1) {
                if (pvflag.get(0) === 0 && high > entryPrice * (1 + p1)) {
                    pvflag.set(1), (pb1 = 1);
                }
                else if (pvflag.get(0) === 1 && high > entryPrice * (1 + p2)) {
                    pvflag.set(2), (pb2 = 1);
                }
                else if (pvflag.get(0) === 2 && high > entryPrice * (1 + p3)) {
                    pvflag.set(3), (pb3 = 1);
                }
                else if (pvflag.get(0) === 3 && high > entryPrice * (1 + p4)) {
                    pvflag.set(4), (pb4 = 1);
                }
            }
            else if (bState.get(0) === -1) {
                if (pvflag.get(0) === 0 && low < entryPrice * (1 - p1)) {
                    pvflag.set(1), (ps1 = 1);
                }
                else if (pvflag.get(0) === 1 && low < entryPrice * (1 - p2)) {
                    pvflag.set(2), (ps2 = 1);
                }
                else if (pvflag.get(0) === 2 && low < entryPrice * (1 - p3)) {
                    pvflag.set(3), (ps3 = 1);
                }
                else if (pvflag.get(0) === 3 && low < entryPrice * (1 - p4)) {
                    pvflag.set(4), (ps4 = 1);
                }
            }
            //聲音------------------------------------------------------------------
            const displayTime = new Date(itime).toLocaleTimeString('en-US');
            const localTimeVar = this._context.new_var();
            const timeDeltaMs = 500;
            const kbarLoadingTimeMs = 10000;
            const kbarIntervalMs = this.PineJS.Std.interval(this._context) * 60 * 1000;
            const isInitialized = new Date().getTime() >
                parseInt(localStorage.getItem('signalInitializedTimestamp') || 'NaN') + kbarLoadingTimeMs;
            const isBarChanging = isNaN(localTimeVar.get(1)) ||
                Math.abs(new Date().getTime() - localTimeVar.get(1)) < timeDeltaMs;
            localTimeVar.set(new Date().getTime());
            /** 進場部位聲音 */
            if (isInitialized) {
                //防止無訊號時出現聲音提醒 當出現新的進場成立flag會被歸零
                //entry_sound_flag_ = 1
            }
            if (isInitialized && isBarChanging) {
                if (bState.get(1) === 1 && bState.get(2) !== 1 && bState.get(2) !== 0) {
                    sounds.playSoundAsiajye2Debounced();
                    entry_sound_flag_ = 1;
                    console.log('✅', displayTime, '閃電多空進多單');
                }
                else if (bState.get(1) === -1 && bState.get(2) !== -1 && bState.get(2) !== 0) {
                    sounds.playSoundAsiajye2Debounced();
                    entry_sound_flag_ = 1;
                    console.log('✅', displayTime, '閃電多空進空單');
                }
            }
            //出場部位聲音
            if (isInitialized && isBarChanging) {
                exit_sound_flag_ = 0;
            }
            const long_exit_condition = (pb1 === 1 && exit_sound_flag_ !== 1) ||
                (pb2 === 1 && exit_sound_flag_ !== 1) ||
                (pb3 === 1 && exit_sound_flag_ !== 1) ||
                (pb4 === 1 && exit_sound_flag_ !== 1);
            const short_exit_condition = (ps1 === 1 && exit_sound_flag_ !== 1) ||
                (ps2 === 1 && exit_sound_flag_ !== 1) ||
                (ps3 === 1 && exit_sound_flag_ !== 1) ||
                (ps4 === 1 && exit_sound_flag_ !== 1);
            /**
             * 測試用 let exitB = NaN let exitS = NaN if (high > close_array.get(1) && sound_flag_ !== 1) {
             * exitB = 1 } else if (low < close_array.get(1) && sound_flag_ !== 1) { exitS = 1 } const
             * test_condition = exitB === 1 || exitS === 1
             */
            if (isInitialized) {
                if (long_exit_condition) {
                    sounds.playSoundAsiajye1Debounced();
                    exit_sound_flag_ = 1;
                    console.log('🚨', displayTime, '閃電多空停利');
                }
                if (short_exit_condition) {
                    sounds.playSoundAsiajye1Debounced();
                    exit_sound_flag_ = 1;
                    console.log('🚨', displayTime, '閃電多空停利');
                }
                const info = this.logPositionInfo(bState.get(0), pvflag.get(0), entry_sound_flag_, exit_sound_flag_);
                console.log(info, bState.get(0), bState.get(1), bState.get(2));
            }
            /** 避免指標在載入歷史資料時傳參至前端 將傳參延後350秒緩衝(時間有可能不夠) */
            const statusIsInitialized = new Date().getTime() >
                parseInt(localStorage.getItem('signalInitializedTimestamp') || 'NaN') + 100;
            if (statusIsInitialized) {
                state.update(state);
            }
            return [pv0, pv1, pb1, pb2, pb3, pb4, ps1, ps2, ps3, ps4];
        },
    },
    metainfo: {
        is_price_study: !0,
        _metainfoVersion: 42,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_0: {
                    color: '#e0232f',
                    textColor: '#e0232f',
                    transparency: 20,
                    visible: true,
                },
                plot_1: {
                    color: '#24b236',
                    textColor: '#24b236',
                    transparency: 20,
                    visible: true,
                },
                plot_2: {
                    color: '#aaaa00',
                    textColor: '#aaaa00',
                    transparency: 20,
                    visible: true,
                },
                plot_3: {
                    color: '#aa5500',
                    textColor: '#aa5500',
                    transparency: 20,
                    visible: true,
                },
                plot_4: {
                    color: '#aa2222',
                    textColor: '#aa2222',
                    transparency: 20,
                    visible: true,
                },
                plot_5: {
                    color: '#ff0000',
                    textColor: '#ff0000',
                    transparency: 20,
                    visible: true,
                },
                plot_6: {
                    color: '#0aa',
                    textColor: '#0aa',
                    transparency: 20,
                    visible: true,
                },
                plot_7: {
                    color: '#4444aa',
                    textColor: '#4444aa',
                    transparency: 20,
                    visible: true,
                },
                plot_8: {
                    color: '#000aaa',
                    textColor: '#000aaa',
                    transparency: 20,
                    visible: true,
                },
                plot_9: {
                    color: '#000aaa',
                    textColor: '#000aaa',
                    transparency: 20,
                    visible: true,
                },
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'chars',
            },
            {
                id: 'plot_5',
                type: 'chars',
            },
            {
                id: 'plot_6',
                type: 'chars',
            },
            {
                id: 'plot_7',
                type: 'chars',
            },
            {
                id: 'plot_8',
                type: 'chars',
            },
            {
                id: 'plot_9',
                type: 'chars',
            },
        ],
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: 'B',
                size: 'small',
                title: 'Shapes',
                transparency: 0,
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: 'S',
                size: 'small',
                title: 'Shapes',
                transparency: 0,
            },
            plot_2: {
                isHidden: false,
                location: 'AboveBar',
                char: '✪',
                size: 'small',
                title: 'Shapes',
            },
            plot_3: {
                isHidden: false,
                location: 'AboveBar',
                char: '✪',
                size: 'small',
                title: 'Shapes',
            },
            plot_4: {
                isHidden: false,
                location: 'AboveBar',
                char: '☻',
                size: 'normal',
                title: 'Shapes',
            },
            plot_5: {
                isHidden: false,
                location: 'AboveBar',
                char: '☻',
                size: 'large',
                title: 'Shapes',
            },
            plot_6: {
                isHidden: false,
                location: 'BelowBar',
                char: '✪',
                size: 'small',
                title: 'Shapes',
            },
            plot_7: {
                isHidden: false,
                location: 'BelowBar',
                char: '✪',
                size: 'small',
                title: 'Shapes',
            },
            plot_8: {
                isHidden: false,
                location: 'BelowBar',
                char: '☻',
                size: 'normal',
                title: 'Shapes',
            },
            plot_9: {
                isHidden: false,
                location: 'BelowBar',
                char: '☻',
                size: 'large',
                title: 'Shapes',
            },
        },
        inputs: [],
    },
});
