import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { useIndicatorStore } from '~/store/useIndicatorStore';
import { asiajyeStore } from '~/pages/asiajye/_private/asiajyeStore';
/** 五價，需要使用useFivePricesStore */
export const pricetrade = createIndicator({
    displayName: '轉折',
    id: 'pricetrade',
    constructorScope: {
        init(_context, _inputCallback) {
            return;
        },
        main(_context, _inputCallback) {
            const state = useIndicatorStore.getState();
            const useprices = asiajyeStore.turningTrade.prices; //usePositionStore.getState().prices
            const useposition = asiajyeStore.turningTrade.position; // usePositionStore.getState().position
            const usePriceValue = asiajyeStore.turningTrade.priceValue; // usePositionStore.getState().priceValue
            const useAssistPriceValue = asiajyeStore.turningTrade.assistPriceValue; // usePositionStore.getState().assistpriceValue
            const prices = useprices ?? null;
            const position = useposition ?? null;
            const open = this.PineJS.Std.open(this._context);
            const close = this.PineJS.Std.close(this._context);
            const low = this.PineJS.Std.low(this._context);
            const high = this.PineJS.Std.high(this._context);
            const open_array = this._context.new_var(open);
            const high_array = this._context.new_var(high);
            const low_array = this._context.new_var(low);
            const close_array = this._context.new_var(close);
            const itime = this.PineJS.Std.time(this._context);
            const start_h = new Date(itime).getHours();
            const start_m = new Date(itime).getMinutes();
            const day_open = this._context.new_var();
            const position_price = day_open.get(0) * usePriceValue +
                (position === 1 ? useAssistPriceValue : -useAssistPriceValue);
            const bState = this._context.new_var();
            const bDt = this._context.new_var();
            const pvflag = this._context.new_var();
            const entriesToday = this._context.new_var();
            let plot_long_line = NaN;
            let plot_short_line = NaN;
            let B_signal = NaN;
            let S_signal = NaN;
            let pb1 = NaN;
            let pb2 = NaN;
            let pb3 = NaN;
            let ps1 = NaN;
            let ps2 = NaN;
            let ps3 = NaN;
            bState.get(1);
            open_array.get(1);
            open_array.get(2);
            high_array.get(1);
            high_array.get(2);
            low_array.get(1);
            low_array.get(2);
            close_array.get(1);
            close_array.get(2);
            //收紅
            const red_bar = close > open;
            //收黑
            const black_bar = close < open;
            //前一根收黑
            const ex_black_bar = close_array.get(1) < open_array.get(1);
            //前一根收黑
            const ex_red_bar = close_array.get(1) > open_array.get(1);
            //收盤>前一根收盤
            const close_up = close > (close_array.get(1) + open_array.get(1)) / 2;
            //收盤<前一根收盤
            const close_dn = close < close_array.get(1);
            const support = (support_price) => {
                return (low_array.get(2) > support_price && // K[2]低點 > 關鍵價
                    low_array.get(1) <= support_price &&
                    red_bar && //K[0]收紅
                    close_up //close[0] > close[1]
                );
            };
            const pressure = (pressure_price) => {
                return (high_array.get(2) < pressure_price && // K[2]低點 < 關鍵價
                    high_array.get(1) >= pressure_price && // K[1]穿關鍵價留下影線
                    black_bar && //K[0]收黑
                    close_dn //close[0] < close[1]
                );
            };
            //========================================
            if (start_h === 8 && start_m === 45) {
                entriesToday.set(5);
                day_open.set(open);
                bState.set(0);
                pvflag.set(0);
                bDt.set(itime);
            }
            if (start_h === 8 && start_m === 46) {
                entriesToday.set(0);
            }
            if (position === 1 &&
                low_array.get(0) < position_price &&
                close > position_price &&
                entriesToday.get(0) <= 3 &&
                itime - bDt.get(0) > 20 * 60 * 1000) {
                B_signal = 1;
                entriesToday.set(entriesToday + 1);
                bState.set(1);
                pvflag.set(0);
                bDt.set(itime);
            }
            if (position === -1 &&
                high_array.get(0) > position_price &&
                close < position_price &&
                entriesToday.get(0) <= 3 &&
                itime - bDt.get(0) > 20 * 60 * 1000) {
                S_signal = 1;
                entriesToday.set(entriesToday + 1);
                bState.set(-1);
                pvflag.set(0);
                bDt.set(itime);
            }
            /** 停利停醒 */
            if (bState.get(0) === 1) {
                if (pvflag.get(0) === 0 && high > position_price + 25) {
                    pvflag.set(1), (pb1 = 1);
                }
                else if (pvflag.get(0) === 1 && high > position_price + 50) {
                    pvflag.set(2), (pb2 = 1);
                }
                else if (pvflag.get(0) === 2 && high > position_price + 75) {
                    pvflag.set(3), (pb3 = 1);
                }
            }
            else if (bState.get(0) === -1) {
                if (pvflag.get(0) === 0 && low < position_price - 25) {
                    pvflag.set(1), (ps1 = 1);
                }
                else if (pvflag.get(0) === 1 && low < position_price - 50) {
                    pvflag.set(2), (ps2 = 1);
                }
                else if (pvflag.get(0) === 2 && low < position_price - 75) {
                    pvflag.set(3), (ps3 = 1);
                }
            }
            if (position === 1) {
                plot_long_line = position_price;
                plot_short_line = NaN;
                state.entryPrice = Number(Math.floor(position_price));
                state.marketPosition = Number(position);
            }
            else if (position === -1) {
                plot_long_line = NaN;
                plot_short_line = position_price;
                state.entryPrice = Number(Math.floor(position_price));
                state.marketPosition = Number(position);
            }
            const statusIsInitialized = new Date().getTime() >
                parseInt(localStorage.getItem('signalInitializedTimestamp') || 'NaN') + 350;
            if (statusIsInitialized) {
                state.update(state);
            }
            return [
                {
                    value: plot_long_line,
                    offset: 0,
                },
                {
                    value: plot_short_line,
                    offset: 0,
                },
                B_signal,
                S_signal,
                pb1,
                pb2,
                pb3,
                ps1,
                ps2,
                ps3,
            ];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            /** 訊號箭頭 */
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'chars',
            },
            {
                id: 'plot_5',
                type: 'chars',
            },
            {
                id: 'plot_6',
                type: 'chars',
            },
            {
                id: 'plot_7',
                type: 'chars',
            },
            {
                id: 'plot_8',
                type: 'chars',
            },
            {
                id: 'plot_9',
                type: 'chars',
            },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 20,
                    visible: !0,
                    color: '#ff0000',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 20,
                    visible: !0,
                    color: '#00cc00',
                },
                /** 訊號箭頭 */
                plot_2: {
                    color: '#ff0000',
                    textColor: '#ff0000',
                    transparency: 0,
                    visible: true,
                },
                plot_3: {
                    color: '#22ee22',
                    textColor: '#22ee22',
                    transparency: 0,
                    visible: true,
                },
                plot_4: {
                    color: '#aa5500',
                    textColor: '#aa5500',
                    transparency: 20,
                    visible: true,
                },
                plot_5: {
                    color: '#aa5500',
                    textColor: '#aa5500',
                    transparency: 20,
                    visible: true,
                },
                plot_6: {
                    color: '#aa5500',
                    textColor: '#aa5500',
                    transparency: 20,
                    visible: true,
                },
                plot_7: {
                    color: '#0aa',
                    textColor: '#0aa',
                    transparency: 20,
                    visible: true,
                },
                plot_8: {
                    color: '#0aa',
                    textColor: '#0aa',
                    transparency: 20,
                    visible: true,
                },
                plot_9: {
                    color: '#0aa',
                    textColor: '#0aa',
                    transparency: 20,
                    visible: true,
                },
            },
            //填充區域
            filledAreasStyle: {
                filledAreaId1: {
                    color: '#fff',
                    transparency: 0,
                    visible: !0,
                },
            },
            palettes: {
                paletteId1: {
                    colors: {
                        0: {
                            color: '#fff',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#fff',
                            width: 0,
                            style: 1,
                        },
                        2: {
                            color: '#fff',
                            width: 0,
                            style: 1,
                        },
                    },
                },
            },
        },
        styles: {
            plot_2: {
                isHidden: false,
                location: 'BelowBar',
                char: '⬆',
                size: 'small',
                text: '',
            },
            plot_3: {
                isHidden: false,
                location: 'AboveBar',
                char: '⬇',
                size: 'small',
                text: '',
            },
            plot_4: {
                isHidden: false,
                location: 'AboveBar',
                char: '✪',
                size: 'small',
                title: 'Shapes',
            },
            plot_5: {
                isHidden: false,
                location: 'AboveBar',
                char: '✪',
                size: 'small',
                title: 'Shapes',
            },
            plot_6: {
                isHidden: false,
                location: 'AboveBar',
                char: '✪',
                size: 'small',
                title: 'Shapes',
            },
            plot_7: {
                isHidden: false,
                location: 'BelowBar',
                char: '✪',
                size: 'small',
                title: 'Shapes',
            },
            plot_8: {
                isHidden: false,
                location: 'BelowBar',
                char: '✪',
                size: 'small',
                title: 'Shapes',
            },
            plot_9: {
                isHidden: false,
                location: 'BelowBar',
                char: '✪',
                size: 'small',
                title: 'Shapes',
            },
        },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});
